<template>
    <div id="app">
        <button class="show-alphabet" @click="showHideAlphabet">
            {{ backButtonLabel }}
        </button>
        <div v-if="!alphabetShowing" class="flex-center main-container">
            <div v-if="currentPattern" class="phrase">
                <span v-for="(w, i) in currentPattern" :key="i" class="word">
                    <span
                        class="letter"
                        v-for="l in w"
                        :key="l.start"
                        :class="{playing: (l.start < currentTime && l.end > currentTime)}"
                    >{{ l.letter }}</span>
                </span>
            </div>
            <div v-if="currentPattern" class="pattern">
                <span v-for="(w, i) in currentPattern" :key="i" class="word-pattern">
                    <span
                        class="letter-pattern"
                        v-for="l in w"
                        :key="l.start"
                        :class="{playing: (l.start < currentTime && l.end > currentTime)}"
                    >{{ l.pattern }}</span>
                </span>
            </div>
            <div v-if="currentPattern" class="footer only-mobile"></div>
            <button
                v-if="currentPattern"
                class="play-pause"
                @click="() => {isPlaying ? stop() : play()}"
            >
                <font-awesome-icon v-if="isPlaying" icon="stop"/>
                <font-awesome-icon v-else icon="play"/>
            </button>
            <div v-if="!currentPattern" class="help">
                Произнеси{{informal ? '' : 'те'}} любое слово или фразу,
                и я воспроизведу её Азбукой Морзе
            </div>
        </div>
        <div v-else class="tables-container">
            <div class="tables">
                <table v-for="part in parts" :key="part" class="alphabet">
                    <tr v-for="letter in getAlphabet(part, parts.length)" :key="letter[0]">
                        <td>{{letter[0]}}</td><td>{{letter[1]}}</td>
                    </tr>
                </table>
            </div>
            <div class="footer"></div>
        </div>
    </div>
</template>

<script>
import { createAssistant, createSmartappDebugger } from '@sberdevices/assistant-client';
import alphabet from './alphabet';

const dot = 1.2 / 15;
const dash = dot * 3;
const pause = dot * 2;

export default {
    name: 'App',
    data() {
        return {
            assistant: null,
            isPlaying: false,
            alphabetShowing: false,
            informal: false,
            currentPattern: null,
            audioContext: null,
            oscillator: null,
            currentTime: 0,
            lastStartTime: 0,
            playInterval: -1,
            parts: [0, 1],
            stopTimeout: -1,
        };
    },
    methods: {
        showPhrase(p) {
            if (this.isPlaying) this.stop();
            this.alphabetShowing = false;
            // process pattern
            this.currentPattern = [];
            let startTime = 0;

            p.split(' ').forEach((w) => {
                startTime = this.addWord(w, startTime);
            });

            this.$nextTick(() => {
                this.play();
            });
        },
        addWord(w, startTime) {
            this.currentPattern.push([]);
            let newStartTime = startTime;
            for (let i = 0; i < w.length; i++) {
                const c = w[i].toUpperCase();
                const code = alphabet.find((a) => a[0] === c);
                if (code) {
                    const duration = this.getLetterDuration(code[1]) + pause;
                    const endTime = newStartTime + duration;
                    const l = {
                        letter: c,
                        pattern: code[1],
                        start: newStartTime,
                        end: endTime,
                    };
                    newStartTime = endTime;
                    this.currentPattern[this.currentPattern.length - 1].push(l);
                }
            }
            return newStartTime;
        },
        getLetterDuration(pattern) {
            let t = 0;
            pattern.split('').forEach((s) => {
                t += s === '·' ? dot : dash;
                t += dot;
            });
            return t;
        },
        play() {
            clearInterval(this.playInterval);
            clearTimeout(this.stopTimeout);
            if (!this.currentPattern) return;
            if (!this.audioContext) {
                const AudioContext = window.AudioContext || window.webkitAudioContext;
                this.audioContext = new AudioContext();
            }
            let t = this.audioContext.currentTime;
            this.lastStartTime = t;

            this.oscillator = this.audioContext.createOscillator();
            this.oscillator.type = 'sine';
            this.oscillator.frequency.value = 600;

            const gainNode = this.audioContext.createGain();
            gainNode.gain.setValueAtTime(0, t);

            this.currentPattern.forEach((word) => {
                word.forEach((letter) => {
                    letter.pattern.split('').forEach((s) => {
                        switch (s) {
                        case '·':
                            gainNode.gain.setValueAtTime(1, t);
                            t += dot;
                            gainNode.gain.setValueAtTime(0, t);
                            t += dot;
                            break;
                        case '−':
                            gainNode.gain.setValueAtTime(1, t);
                            t += dash;
                            gainNode.gain.setValueAtTime(0, t);
                            t += dot;
                            break;
                        /* case ' ':
                            t += pause;
                            break; */
                        default:
                            break;
                        }
                    });
                    t += pause;
                });
            });

            this.oscillator.connect(gainNode);
            gainNode.connect(this.audioContext.destination);

            this.oscillator.onended = () => {
                this.isPlaying = false;
                clearInterval(this.playInterval);
                clearTimeout(this.stopTimeout);
                this.currentTime = 0;
                this.oscillator = null;
            };
            this.isPlaying = true;
            this.oscillator.start();
            this.playInterval = setInterval(this.updatePlayTime, 100);
            this.stopTimeout = setTimeout(this.stop, (t - this.lastStartTime) * 1000);
        },
        stop() {
            clearTimeout(this.stopTimeout);
            if (!this.audioContext || !this.oscillator || !this.isPlaying) return;
            this.oscillator.stop(this.audioContext.currentTime + 0.5);
            // this.oscillator = null;
        },
        updatePlayTime() {
            if (!this.audioContext) return;
            this.currentTime = this.audioContext.currentTime - this.lastStartTime + 0.05;
        },
        showHideAlphabet() {
            if (!this.isPlaying) this.stop();
            this.alphabetShowing = !this.alphabetShowing;
        },
        getAlphabet(part, parts) {
            const len = alphabet.length;
            const partLen = Math.ceil(len / parts);
            return alphabet.slice(part * partLen, part * partLen + partLen);
        },
    },
    computed: {
        showPattern() {
            return this.currentPattern.split('').join(' ');
        },
        backButtonLabel() {
            return this.alphabetShowing ? '< Назад' : 'Алфавит Морзе';
        },
    },
    mounted() {
        if (window.matchMedia('(min-width: 768px)').matches) {
            this.parts = [0, 1, 2, 3];
        }

        const initialize = (getState) => {
            if (process.env.NODE_ENV === 'development') {
                return createSmartappDebugger({
                    token: process.env.VUE_APP_SALUTE_TOKEN,
                    initPhrase: 'запусти азбуку морзе',
                    getState,
                });
            }

            // production
            return createAssistant({ getState });
        };

        this.assistant = initialize(() => {});
        this.assistant.on('data', (command) => {
            if (process.env.NODE_ENV === 'development') console.log(command);

            if (command.type === 'smart_app_data') {
                if (command.action === 'close') {
                    this.assistant.close();
                }
                if (command.action === 'phrase' && command.data.phrase) {
                    this.showPhrase(command.data.phrase);
                }
                if (command.action === 'play' && !this.isPlaying && this.currentPattern) {
                    this.play();
                }
                if (command.action === 'stop' && this.isPlaying) {
                    this.stop();
                }
            } else if (command.type === 'insets') {
                let b = command.insets && command.insets.bottom;
                if (b) {
                    const root = document.documentElement;
                    if (b > 200) b = 144; // TODO await fix
                    root.style.setProperty('--bottom-inset', `${b}px`);
                }
            } else if (command.type === 'character') {
                if (command.character && command.character.id) {
                    this.informal = command.character.id.toLowerCase() === 'joy';
                }
            }
        });
    },
};
</script>

<style>
@font-face {
    font-family: "Polyglott";
    src: url("./assets/polyglott.ttf") format("truetype");
}

html, body {
    font-family: Polyglott, serif;
    /*position: fixed;*/
    margin: 0;
    padding: 0;
    width: 100%;
    /*min-height: 100vh;*/

    background-image: url("./assets/bg.jpg")!important;
    background-repeat: repeat;
}

* {
    box-sizing: border-box;
    user-select: none;
    background-image: none;
    max-width: 100vw;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

:root {
    --bottom-inset: 150px;
}

button {
    padding: 0;
    border: none;
    outline: none;
}

button:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.25) !important;
}

.flex-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - var(--bottom-inset));
}

.phrase {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
}

.pattern {
    font-size: 30px;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
}

.play-pause {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid black;
    color: black;
    font-size: 20px;
}

.letter {
}

.letter-pattern {
    margin-right: 5px;
}

.word {
    margin-left: 5px;
    font-size: 24px;
    font-weight: 600;
}

.word-pattern {
    margin-left: 10px;
}

.playing {
    color: #db0000;
}

.show-alphabet {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 7px 10px;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 10px;
    font-family: Polyglott, serif;
    font-size: 20px;
}

table, th, td {
    border: 1px solid black;
}

table {
    border-collapse: collapse;
}

td {
    padding: 5px 10px;
    font-size: 25px;
}

.alphabet {
    font-size: 20px;
}

.tables {
    margin: 70px auto 0 auto;
    display: flex;
    justify-content: center;
}

.footer {
    margin-top: 20px;
    height: var(--bottom-inset);
    width: 1px;
}

.tables table {
    margin-right: 10px;
}

.tables-container {
}

.help {
    padding: 0 20px;
    text-align: center;
    font-size: 25px;
}

.only-mobile {
    display: none;
}

@media (max-width: 767px) {
    .only-mobile {
        display: block;
    }

    .help {
        margin-top: -55px;
    }

    .phrase {
        margin-bottom: 10px;
    }

    .main-container {
        margin-top: 55px;
        /*min-height: calc(100vh - var(--bottom-inset) - 55px - );*/
    }

    .play-pause {
        position: fixed;
        right: 10px;
        bottom: calc(10px + var(--bottom-inset));
        background-color: #0000001f;
        z-index: 1;
    }
}

@media (min-width: 768px) {
    .word {
        font-size: 40px;
    }

    .phrase {
        margin-bottom: 30px;
    }

    .pattern {
        font-size: 40px;
        margin-bottom: 30px;
    }

    .show-alphabet {
        font-size: 30px;
        padding: 7px 14px;
    }

    .play-pause {
        width: 100px;
        height: 100px;
        font-size: 30px;
    }

    td {
        font-size: 30px;
    }

    .help {
        font-size: 30px;
        max-width: 50vw;
    }
}

@media (min-width: 1281px) {
    .word {
        font-size: 50px;
    }

    .pattern {
        font-size: 50px;
    }

    .show-alphabet {
        font-size: 40px;
        padding: 7px 14px;
    }

    .play-pause {
        width: 150px;
        height: 150px;
        font-size: 45px;
    }

    td {
        font-size: 45px;
    }

    .help {
        font-size: 45px;
        max-width: 50vw;
    }
}
</style>
